import Vue from "vue";
import App from "./app/App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import "@/assets/css/tailwind.css";

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
