<template>
  <div class="flex flex-col w-full items-center">
    <!-- Loading state -->
    <div
      v-if="adjustmentsLoading"
      class="table-fixed w-full border-solid p-4 border-gray-200 border-2 mt-3 adjustments-table overflow-y-auto"
    >
      <p class="text-lg">
        {{ $t("production_order_detail.table.loading") }}
      </p>
    </div>

    <!-- Table when data exists -->
    <table
      v-else-if="adjustments.length > 0"
      class="table-fixed w-full border-solid border-gray-200 border-2 mt-3 adjustments-table overflow-y-auto"
    >
      <thead>
        <tr class="text-sm">
          <th class="w-2/12 px-2">
            {{ $t("production_order_detail.table.headers.size_item") }}
          </th>
          <th class="w-2/12 px-2">
            {{ $t("production_order_detail.table.headers.body_measurements") }}
          </th>
          <th :class="`${isRtwTailoring ? 'w-1/12' : 'w-2/12'} px-2`">
            {{ $t("production_order_detail.table.headers.fit_specifications") }}
          </th>
          <th :class="`${isRtwTailoring ? 'w-1/12' : 'w-2/12'} px-2`">
            {{ $t("production_order_detail.table.headers.drop_adjustments") }}
          </th>
          <th class="w-2/12 px-2">
            {{ $t("production_order_detail.table.headers.fit_adjustments") }}
          </th>
          <th :class="`${isRtwTailoring ? 'w-1/12' : 'w-2/12'} px-2`">
            {{ $t("production_order_detail.table.headers.production_order") }}
          </th>
          <th v-if="isRtwTailoring" class="w-2/12 px-2">
            {{
              $t("production_order_detail.table.headers.product_measurements")
            }}
          </th>
          <th v-if="isRtwTailoring" class="w-1/12 px-2">
            {{ $t("production_order_detail.table.headers.alterations") }}
          </th>
          <th />
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(adjustment, index) in adjustments"
          :key="index"
          :style="
            isPdf
              ? {
                  borderBottom: '1px solid #edf2f7',
                  height: '40px'
                }
              : {}
          "
        >
          <td>{{ adjustment.size_item }}</td>
          <td>{{ adjustment.body_measurement }}</td>
          <td>{{ adjustment.fit_specification }}</td>
          <td>
            {{
              adjustment.calculated_drop !== "0.0"
                ? adjustment.calculated_drop
                : "-"
            }}
          </td>
          <td
            :style="
              isPdf
                ? {
                    padding: '12px 12px',
                    height: '40px'
                  }
                : {}
            "
          >
            <template v-if="!isPdf">
              <input
                :id="`fit_adjustment_${index}`"
                type="number"
                v-model="fitAdjustments[index].value"
                step="0.1"
                class="w-20 mt-1 py-2 pl-2 text-center border-2 border-solid border-black rounded-md"
              />
            </template>
            <template v-else>
              {{ fitAdjustments[index].value || "0" }}
            </template>
          </td>
          <td>{{ getProductionOrderValue(index) }}</td>
          <td v-if="isRtwTailoring">
            {{ adjustment.product_measurement }}
          </td>
          <td v-if="isRtwTailoring">
            {{ getAlterationValue(index, adjustment.product_measurement) }}
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Error/No data states -->
    <div v-else>
      <p v-if="adjustmentsLoading === false && adjustmentsError === ''">
        {{ $t("production_order_detail.table.no_data") }}
      </p>
      <p v-else-if="adjustmentsLoading === false && adjustmentsError !== ''">
        {{ adjustmentsError }}
      </p>
      <i v-else class="fas fa-circle-notch fa-spin fa-5x mt-5"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "productionOrderTable",

  props: {
    adjustments: {
      type: Array,
      required: true
    },
    fitAdjustments: {
      type: Array,
      required: true
    },
    adjustmentsLoading: {
      type: Boolean,
      default: false
    },
    adjustmentsError: {
      type: String,
      default: ""
    },
    isRtwTailoring: {
      type: Boolean,
      default: false
    },
    isPdf: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    getProductionOrderValue(index) {
      const fitAdjustment = parseFloat(this.fitAdjustments[index].value) || 0;
      const adjustment = this.adjustments[index];

      return (
        parseFloat(adjustment.body_measurement) +
        parseFloat(adjustment.fit_specification) +
        parseFloat(adjustment.calculated_drop) +
        fitAdjustment
      ).toFixed(1);
    },

    getAlterationValue(index, productSize) {
      const productionOrderValue = this.getProductionOrderValue(index);
      return parseFloat(productionOrderValue - productSize).toFixed(1);
    }
  }
};
</script>

<style scoped></style>
