<template>
  <div
    v-if="showModal"
    class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50"
  >
    <div
      class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
    ></div>

    <div
      class="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto"
    >
      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="modal-content text-left">
        <!--Title-->
        <div class="flex justify-between items-center bg-red-700 p-4">
          <p class="text-2xl font-bold text-white">
            {{ $t("json_modal.view_json") }}
          </p>
          <div class="modal-close cursor-pointer z-50" @click="onClose">
            <svg
              class="fill-current text-white"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <div class="p-4">
          <!--Body-->
          <code>{{ beautifiedJSON }}</code>
        </div>

        <!--Footer-->
        <div class="flex justify-end p-4">
          <button
            class="px-4 bg-transparent p-3 text-blue-800 hover:bg-gray-100 hover:text-indigo-400 mr-2"
            @click="onClose"
          >
            {{ $t("json_modal.buttons.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JSONModal",
  props: {
    beautifiedJSON: {
      required: true
    },
    showModal: {
      type: Boolean
    },
    onClose: {
      type: Function
    }
  }
};
</script>
