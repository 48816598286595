import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Login from "@/pages/auth/login.vue";
import Index from "@/pages/main/index.vue";
import BodyprintEditView from "@/pages/bodyprint/bodyprint-edit-view.vue";
import BodyprintCheckView from "@/pages/bodyprint/bodyprint-check-view.vue";
import BodyprintQueueView from "@/pages/bodyprint/bodyprint-queue-view.vue";
import ProductionListView from "@/pages/production/production-list-view.vue";
import ProductionOrderView from "@/pages/production/production-order-view.vue";

Vue.use(VueRouter);

export const ROUTE_LOGIN = "login";
export const ROUTE_INDEX = "index";
export const ROUTE_BODYPRINT_QUEUE = "bodyprint_queue";
export const ROUTE_BODYPRINT_EDIT = "bodpyrint_edit";
export const ROUTE_BODYPRINT_CHECK = "bodyprint_check";
export const ROUTE_PRODUCTION = "production";
export const ROUTE_PRODUCTION_ORDER_EDIT = "production_order_edit";

const routes = [
  {
    path: "/login",
    name: ROUTE_LOGIN,
    component: Login,
    meta: {
      auth: false
    }
  },
  {
    path: "/",
    name: ROUTE_INDEX,
    component: Index,
    meta: {
      auth: true
    }
  },
  {
    path: "/bodyprint",
    name: ROUTE_BODYPRINT_QUEUE,
    component: BodyprintQueueView,
    meta: {
      auth: true
    }
  },
  {
    path: "/bodyprint/edit/:queue_id",
    name: ROUTE_BODYPRINT_EDIT,
    component: BodyprintEditView,
    meta: {
      auth: true
    }
  },
  {
    path: "/bodyprint/check/:queue_id",
    name: ROUTE_BODYPRINT_CHECK,
    component: BodyprintCheckView,
    meta: {
      auth: true
    }
  },
  {
    path: "/production",
    name: ROUTE_PRODUCTION,
    component: ProductionListView,
    meta: {
      auth: true
    }
  },
  {
    path: "/production/order/:order_id",
    name: ROUTE_PRODUCTION_ORDER_EDIT,
    component: ProductionOrderView,
    meta: {
      auth: true
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  store.dispatch("auth/authCheck").then(() => {
    const authenticated = store.state.auth.loggedIn;

    if (to.matched.length === 0) {
      if (authenticated) {
        store.dispatch("application/updateCurrentRoute", ROUTE_INDEX);
        next({ name: ROUTE_INDEX });
      } else {
        store.dispatch("application/updateCurrentRoute", ROUTE_LOGIN);
        next({ name: ROUTE_LOGIN });
      }
    }

    if (to.matched.some(m => m.path === "/login") && authenticated) {
      store.dispatch("application/updateCurrentRoute", ROUTE_INDEX);
      next({ name: ROUTE_INDEX });
    } else if (to.matched.some(m => m.meta.auth) && !authenticated) {
      store.dispatch("application/updateCurrentRoute", ROUTE_LOGIN);
      next({ name: ROUTE_LOGIN });
    } else {
      store.dispatch("application/updateCurrentRoute", to.name);
      next();
    }
  });
});

export default router;
