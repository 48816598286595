<template>
  <div
    class="bg-white py-4 px-6"
    v-if="instruction.name && instruction.image && instruction.text"
  >
    <p class="text-xl font-semibold text-blue-800">{{ instruction.name }}</p>
    <div class="w-full h-1 bg-gray-400" />
    <div class="w-full flex justify-center mt-2">
      <img
        class="w-auto h-full instruction-image"
        :src="instruction.image"
        alt="Measure instruction image"
      />
      <div class="w-full ml-5">
        <p>{{ instruction.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import controlMixin from "../control-mixin";
import { mapGetters, mapState } from "vuex";

export default {
  name: "MeasureInstructions",
  props: {},
  components: {},
  mixins: [controlMixin],
  data() {
    return {
      instruction: {
        name: null,
        image: null,
        text: null
      }
    };
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  computed: {
    ...mapState({
      newCoordinates: "bodyprint/newCoordinates",
      activeCoordinates: "bodyprint/activeCoordinates"
    }),
    ...mapGetters({
      getMeasureStep: "bodyprint/getMeasureStep",
      getArea: "configuration/getArea",
      getNewCoordinates: "bodyprint/getNewCoordinates"
    })
  },
  methods: {
    getInstructionInformation() {
      const handle = this.getArea.handles.find(
        handle => handle.puppetPointName === this.getNewCoordinates.pointNumber
      );

      var newInstruction = {
        name: null,
        image: null,
        text: null
      };

      if (handle !== undefined) {
        switch (handle.name) {
          // Top head left
          case "measurement_left_handle_top":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_left_handle_top.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_left_top.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_left_handle_top.text"
            );
            break;
          // Top head back
          case "measurement_back_handle_top":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_back_handle_top.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_back_top.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_back_handle_top.text"
            );
            break;
          // Bottom feet left
          case "measurement_left_handle_feet_front":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_left_handle_feet_front.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_left_feet_front.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_left_handle_feet_front.text"
            );
            break;
          case "measurement_left_handle_feet_back":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_left_handle_feet_back.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_left_feet_back_animated.gif`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_left_handle_feet_back.text"
            );
            break;
          // Bottom feet back
          case "measurement_back_handle_feet_left":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_back_handle_feet_left.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_back_feet_left.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_back_handle_feet_left.text"
            );
            break;
          case "measurement_back_handle_feet_right":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_back_handle_feet_right.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_back_feet_left.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_back_handle_feet_right.text"
            );
            break;
          // Left head
          case "measurement_left_handle_neck_depth":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_left_handle_neck_depth.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_left_neck_front.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_left_handle_neck_depth.text"
            );
            break;
          case "measurement_left_handle_spine_top":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_left_handle_spine_top.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_left_neck_back.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_left_handle_spine_top.text"
            );
            break;
          // Back head
          case "measurement_back_handle_neck_left":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_back_handle_neck_left.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_back_neck_left.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_back_handle_neck_left.text"
            );
            break;
          case "measurement_back_handle_neck_right":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_back_handle_neck_right.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_back_neck_right.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_back_handle_neck_right.text"
            );
            break;
          // Back left arm
          case "measurement_back_handle_shoulder_left":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_back_handle_shoulder_left.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_back_shoulder_left_animated.gif`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_back_handle_shoulder_left.text"
            );
            break;
          case "measurement_back_handle_elbow_left":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_back_handle_elbow_left.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_back_elbow_left.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_back_handle_elbow_left.text"
            );
            break;
          case "measurement_back_handle_hand_left":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_back_handle_hand_left.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_back_hand_left.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_back_handle_hand_left.text"
            );
            break;
          // Back right arm
          case "measurement_back_handle_shoulder_right":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_back_handle_shoulder_right.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_back_shoulder_right_animated.gif`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_back_handle_shoulder_right.text"
            );
            break;
          case "measurement_back_handle_elbow_right":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_back_handle_elbow_right.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_back_elbow_right.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_back_handle_elbow_right.text"
            );
            break;
          case "measurement_back_handle_hand_right":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_back_handle_hand_right.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_back_hand_right.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_back_handle_hand_right.text"
            );
            break;
          // Left arm left
          case "measurement_left_handle_shoulders":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_left_handle_shoulders.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_left_shoulders.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_left_handle_shoulders.text"
            );
            break;
          case "measurement_left_handle_elbows":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_left_handle_elbows.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_left_elbows.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_left_handle_elbows.text"
            );
            break;
          case "measurement_left_handle_hands":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_left_handle_hands.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_left_hands.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_left_handle_hands.text"
            );
            break;
          // Back crotch
          case "measurement_back_handle_crotch":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_back_handle_crotch.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_back_crotch.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_back_handle_crotch.text"
            );
            break;
          // Left ankle
          case "measurement_left_handle_ankles":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_left_handle_ankles.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_left_ankle.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_left_handle_ankles.text"
            );
            break;
          // Left chest
          case "measurement_left_handle_chest_front":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_left_handle_chest_front.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_left_chest_front.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_left_handle_chest_front.text"
            );
            break;
          case "measurement_left_handle_chest_back":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_left_handle_chest_back.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_left_chest_back.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_left_handle_chest_back.text"
            );
            break;
          // Back chest
          case "measurement_back_handle_chest_left":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_back_handle_chest_left.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_back_chest_left.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_back_handle_chest_left.text"
            );
            break;
          case "measurement_back_handle_chest_right":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_back_handle_chest_right.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_back_chest_right.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_back_handle_chest_right.text"
            );
            break;
          // Left waist
          case "measurement_left_handle_waist_front":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_left_handle_waist_front.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_left_waist_front.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_left_handle_waist_front.text"
            );
            break;
          case "measurement_left_handle_waist_back":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_left_handle_waist_back.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_left_waist_back.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_left_handle_waist_back.text"
            );
            break;
          // Back waist
          case "measurement_back_handle_waist_left":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_back_handle_waist_left.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_back_waist_left.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_back_handle_waist_left.text"
            );
            break;
          case "measurement_back_handle_waist_right":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_back_handle_waist_right.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_back_waist_right.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_back_handle_waist_right.text"
            );
            break;
          // Left band
          case "measurement_left_handle_band_front":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_left_handle_band_front.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_left_band_front_animated.gif`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_left_handle_band_front.text"
            );
            break;
          case "measurement_left_handle_band_back":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_left_handle_band_back.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_left_band_back_animated.gif`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_left_handle_band_back.text"
            );
            break;
          // Back band
          case "measurement_back_handle_band_left":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_back_handle_band_left.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_back_band_left.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_back_handle_band_left.text"
            );
            break;
          case "measurement_back_handle_band_right":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_back_handle_band_right.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_back_band_right.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_back_handle_band_right.text"
            );
            break;
          // Left hips
          case "measurement_left_handle_hips_front":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_left_handle_hips_front.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_left_hips_front.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_left_handle_hips_front.text"
            );
            break;
          case "measurement_left_handle_hips_back":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_left_handle_hips_back.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_left_hips_back.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_left_handle_hips_back.text"
            );
            break;
          // Back hips
          case "measurement_back_handle_hips_left":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_back_handle_hips_left.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_back_hips_left.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_back_handle_hips_left.text"
            );
            break;
          case "measurement_back_handle_hips_right":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_back_handle_hips_right.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_back_hips_right.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_back_handle_hips_right.text"
            );
            break;
          // Left upper thighs
          case "measurement_left_handle_upper_thighs_front":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_left_handle_upper_thighs_front.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_left_upper_thigh_front.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_left_handle_upper_thighs_front.text"
            );
            break;
          case "measurement_left_handle_upper_thighs_back":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_left_handle_upper_thighs_back.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_left_upper_thigh_back.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_left_handle_upper_thighs_back.text"
            );
            break;
          // Back upper thighs
          case "measurement_left_handle_upper_thigh_left_left":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_left_handle_upper_thigh_left_left.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_back_upper_thigh_left.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_left_handle_upper_thigh_left_left.text"
            );
            break;
          case "measurement_left_handle_upper_thigh_left_right":
            newInstruction.name = this.$t(
              "measure_instructions.measurement_left_handle_upper_thigh_left_right.name"
            );
            newInstruction.image = require(`../../assets/measure/measurement_info_back_upper_thigh_right.jpg`);
            newInstruction.text = this.$t(
              "measure_instructions.measurement_left_handle_upper_thigh_left_right.text"
            );
            break;
          default:
            newInstruction.image = null;
            newInstruction.text = null;
            break;
        }
      }

      this.instruction = newInstruction;
    }
  },
  watch: {
    activeCoordinates: {
      handler() {
        this.getInstructionInformation();
      },
      deep: true
    }
  }
};
</script>

<style>
.instruction-image {
  height: 200px !important;
  border: 2px solid black;
}
</style>
