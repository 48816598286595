<template>
  <div class="flex min-h-screen max-h-screen flex-col">
    <div v-if="!isLoading" class="flex-grow">
      <div class="flex mx-auto p-4 w-full justify-center" v-if="getBodyPrint">
        <div class="flex w-full">
          <div class="w-auto">
            <div class="border border-white">
              <crop-view
                v-if="current_step === 'crop'"
                :image-source="this.getImageBack"
                :projection="'back'"
                :is-active="current_projection === 'back'"
                :initial-crop="this.getGuide('back').crop"
                side="back"
                :flipped="this.back_image_flipped"
              />
              <bodyprint-view
                v-if="current_step === 'measure'"
                :image-source="this.getImageBack"
                :is-active="current_projection === 'back'"
                side="back"
                :flipped="this.back_image_flipped"
                :picture-height="getPictureHeight"
                :picture-width="getPictureWidth"
                :back-guide="getCropBack"
                :side-guide="getCropSide"
              />
            </div>
          </div>
          <div class="w-auto">
            <div class="border border-white">
              <crop-view
                v-if="current_step === 'crop'"
                :image-source="this.getImageSide"
                :projection="'left'"
                :is-active="current_projection === 'left'"
                :initial-crop="this.getGuide('left').crop"
                side="left"
                :flipped="side_image_flipped"
              />
              <bodyprint-view
                v-if="current_step === 'measure'"
                :image-source="this.getImageSide"
                :is-active="current_projection === 'left'"
                side="left"
                :flipped="side_image_flipped"
                :picture-height="getPictureHeight"
                :picture-width="getPictureWidth"
                :back-guide="getCropBack"
                :side-guide="getCropSide"
              />
            </div>
          </div>
          <div class="w-auto" v-if="current_step === 'measure'">
            <measure-set :image-flipped="imageFlippedSet()" />
          </div>
        </div>
        <div
          class="flex-grow flex flex-col justify-between bg-gray-300 -m-4 p-4"
        >
          <div class="flex-grow">
            <div class="text-xl font-semibold text-blue-800 mb-4">
              {{
                this.current_step === "crop"
                  ? $t("bodyprint_edit.crop_instructions")
                  : $t("bodyprint_edit.measure_instructions")
              }}
            </div>
            {{ "current step is: " + this.current_step }}
            <!-- <p class="text-base mb-4">
              Move the red handles to the body, so that the edges touch the
              dotted line of the crop handles.
            </p>
            <p class="text-base mb-4">
              Once all the crop handles have been correctly placed you can use
              next to crop the next view.
            </p>-->
            <div class="my-4">
              <crop-instructions
                v-if="current_step === 'crop'"
                :projection="current_projection"
              />
            </div>
            <div class="my-4">
              <measure-instructions v-if="current_step === 'measure'" />
            </div>
            <div class="my-4" v-if="getAuth.bodyprintSearch === true">
              <bodyprint-info />
            </div>
          </div>
          <!-- Controls and buttons -->
          <div class="flex flex-col">
            <controls />
            <div class="flex w-full bg-gray-200 mx-auto p-4">
              <div class="w-full grid grid-cols-3 gap-2">
                <button
                  class="col-span-1 button py-2 bg-gray-200 text-white"
                  :disabled="true"
                  v-if="
                    this.current_step === 'crop' &&
                      this.current_projection === 'back'
                  "
                ></button>
                <button
                  class="col-span-1 button py-2 bg-blue-800 text-white"
                  @click="goToPreviousStep"
                  v-else
                >
                  {{ getPreviousStepText() }}
                </button>
                <button
                  class="col-span-1 button py-2 bg-red-700 text-white"
                  @click="() => (showProblemModal = true)"
                >
                  {{ $t("bodyprint_edit.buttons.reject") }}
                </button>
                <!--                  <button-->
                <!--                    class="button py-2 px-4 bg-yellow-700 text-white"-->
                <!--                    @click="() => (showJSONModal = true)"-->
                <!--                  >-->
                <!--                    Show JSON-->
                <!--                  </button>-->
                <button
                  class="col-span-1 button py-2 bg-blue-800 text-white"
                  @click="goToNextStep"
                >
                  {{ getNextStepText() }}
                </button>
                <div
                  class="col-span-3 grid grid-cols-3 gap-2"
                  v-show="this.current_step === 'crop'"
                >
                  <button
                    class="col-span-3 button py-2 w-full bg-blue-800 text-white"
                    @click="() => (side_image_flipped = !side_image_flipped)"
                  >
                    {{ $t("bodyprint_edit.buttons.flip_image") }}
                  </button>
                </div>
                <div
                  class="col-span-3 grid grid-cols-3 gap-2"
                  v-show="this.current_step === 'measure'"
                >
                  <button
                    class="col-span-3 button py-2 bg-blue-800 text-white"
                    @click="returnToCropping"
                  >
                    {{ $t("bodyprint_edit.buttons.back_to_crop") }}
                  </button>
                  <button
                    class="col-span-3 button py-2 bg-yellow-700 text-white"
                    @click="() => prepareMeasurementsModal()"
                  >
                    {{ $t("bodyprint_edit.buttons.view_measurements") }}
                  </button>
                  <!--                <button-->
                  <!--                  class="button py-2 px-8 w-full bg-yellow-700 text-white mx-2"-->
                  <!--                  @click="showUpdateDialog = true"-->
                  <!--                >-->
                  <!--                  Update bodyprint-->
                  <!--                </button>-->
                  <button
                    class="col-span-3 button py-2 bg-blue-800 text-white"
                    @click="showSaveDialog = true"
                    v-show="this.isQueueItem"
                  >
                    {{ $t("bodyprint_edit.buttons.finalize") }}
                  </button>
                </div>
                <button
                  class="col-span-3 button py-2 bg-red-700 text-white"
                  @click="showCancelDialog = true"
                  v-show="this.isQueueItem"
                >
                  {{ $t("bodyprint_edit.buttons.cancel") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="flex flex-col mx-auto p-4 w-full h-full items-center text-blue-800 text-2xl my-12"
      style="text-align: center"
    >
      {{ $t("bodyprint_edit.loading") }}
    </div>
    <div>
      <problem-modal
        :showModal="showProblemModal"
        :onClose="() => (showProblemModal = false)"
        :onSubmit="reason => rejectBodyPrint(reason)"
      />
    </div>
    <div>
      <JSON-modal
        :beautifiedJSON="beautifiedJSON"
        :showModal="showJSONModal"
        :onClose="() => (showJSONModal = false)"
      />
    </div>
    <div>
      <measurements-modal
        :loading="measurementsLoading"
        :showModal="showMeasurementsModal"
        :bodyprint-id="measurementsBodyprint"
        :onClose="
          () => {
            showMeasurementsModal = false;
            measurementsBodyprint = null;
          }
        "
      />
    </div>
    <Dialog
      v-if="showCancelDialog"
      @confirmed="stopEditing"
      @close="showCancelDialog = false"
      color="red"
      :title="$t('bodyprint_edit.dialogs.return.title').toString()"
      :message="$t('bodyprint_edit.dialogs.return.message').toString()"
    />
    <Dialog
      v-if="showSaveDialog"
      @confirmed="completeBodyPrint"
      @close="showSaveDialog = false"
      :title="$t('bodyprint_edit.dialogs.save.title').toString()"
      :message="$t('bodyprint_edit.dialogs.save.message').toString()"
    />
  </div>
</template>

<script>
import CropView from "../../components/crop/crop-view.vue";
import Dialog from "../../components/dialog.vue";
import MeasureSet from "../../components/measure/measure-set.vue";
import Controls from "../../components/controls.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import beautify from "json-beautify";
import CropInstructions from "../../components/crop/crop-instructions.vue";
import JSONModal from "../../components/modals/json-modal.vue";
import ProblemModal from "../../components/modals/problem-modal.vue";
import MeasurementsModal from "../../components/modals/measurements-modal.vue";
import MeasureInstructions from "../../components/measure/measure-instructions.vue";
import { ROUTE_BODYPRINT_QUEUE } from "@/router";
import BodyprintView from "@/components/bodyprint/bodyprint-view.vue";
import BodyprintInfo from "@/components/bodyprint/bodyprint-info.vue";

export default {
  name: "BodyprintEditView",
  components: {
    BodyprintInfo,
    BodyprintView,
    CropView,
    Dialog,
    MeasureSet,
    Controls,
    JSONModal,
    ProblemModal,
    MeasurementsModal,
    MeasureInstructions,
    CropInstructions
  },
  created() {
    if (!this.getBodyPrint) {
      this.getViewData();
      return;
    }

    this.isLoading = false;

    this.setImageWindowHeight();
    window.addEventListener("resize", this.setImageWindowHeight);
    window.addEventListener("keydown", this.handleKeydown, null);
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {});
    window.removeEventListener("keydown", this.handleKeydown);
  },
  computed: {
    ...mapGetters({
      getAuth: "auth/getAuth",
      getArea: "configuration/getArea",
      getAreas: "configuration/getAreas",
      getMeasureStep: "bodyprint/getMeasureStep",
      getGuide: "bodyprint/getGuide",
      getBodyPrint: "bodyprint/getBodyPrint",
      isQueueItem: "bodyprint/isQueueItem",
      getImageSide: "bodyprint/getImageSide",
      getImageBack: "bodyprint/getImageBack",
      getPictureHeight: "bodyprint/getPictureHeight",
      getPictureWidth: "bodyprint/getPictureWidth",
      getCropBack: "bodyprint/getCropBack",
      getCropSide: "bodyprint/getCropSide"
    }),
    showCrop() {
      return this.current_step === "start" || this.current_step === "crop";
    },
    checkCrop() {
      let crop = this.getGuide(this.current_projection).crop;
      let bodyprint = this.getBodyPrint;
      return !!(bodyprint.points.length > 0 || crop.x2);
    },
    currentStep() {
      if (this.current_step === "start") {
        return this.$t("bodyprint_edit.current_step.start");
      }
      if (this.current_step === "review") {
        return this.$t("bodyprint_edit.current_step.review");
      }
      if (this.current_step === "crop") {
        return this.$t("bodyprint_edit.current_step.crop");
      }
      if (this.current_step === "measure") {
        return this.$t("bodyprint_edit.current_step.measure");
      }
      return "";
    },
    // currentImage() {
    //     return this.getGuide(this.current_projection).image_source
    // },
    beautifiedJSON() {
      return beautify(this.$store.getters["bodyPrint"], null, 2, 80);
    }
  },
  data() {
    return {
      isLoading: true,
      measurementsLoading: false,
      showSaveDialog: false,
      showCancelDialog: false,
      rejectionReason: "",
      token: "",
      showProblemModal: false,
      showJSONModal: false,
      showMeasurementsModal: false,
      measurementsBodyprint: null,
      bodyprint: null,
      current_step: "crop",
      current_projection: "back",
      measure_step: 0,
      image_source_back: "lady_orange_back.png",
      image_source_side: "lady_orange_side.png",
      back_crop: {
        x: 0.27,
        y: 0.18,
        width: 0.47,
        height: 0.66,
        projection: "back"
      },
      side_crop: {
        x: 0.31,
        y: 0.2,
        width: 0.44,
        height: 0.6,
        projection: "left"
      },
      back_image_flipped: false,
      side_image_flipped: false
    };
  },
  watch: {
    currentStep() {
      this.setCurrentStep(this.current_step);
    }
  },
  methods: {
    ...mapMutations({
      setActiveCoordinates: "bodyprint/setActiveCoordinates",
      setWindowHeight: "bodyprint/setWindowHeight",
      setCurrentStep: "bodyprint/setCurrentStep",
      setMeasureStep: "bodyprint/setMeasureStep",
      updateConstraints: "bodyprint/updateConstraints"
    }),
    ...mapActions(["auth/authLogout"]),
    ...mapActions({
      postBodyprint: "bodyprint/postBodyprint",
      setQueueItemStatus: "bodyprint/setQueueItemStatus",
      cancelQueueItem: "bodyprint/cancelQueueItem",
      getBodyprintSizes: "bodyprint/getBodyprintSizes"
    }),
    getViewData() {
      const queueId = this.$route.params.queue_id;
      console.log(queueId);
      this.$store
        .dispatch("bodyprint/requestBodyprint", {
          id: queueId
        })
        .then(() => {
          var bp = this.$store.getters["bodyprint/getBodyPrint"];
          if (bp.user) {
            this.$store.dispatch("bodyprint/getBodyprintSizes", {
              user: bp.user,
              bodyprint: bp._id
            });
            this.$store
              .dispatch("bodyprint/getImages", bp.user)
              .then(() => {
                this.$store.dispatch("bodyprint/editBodyprint").then(() => {
                  const finishGetViewData = () => {
                    this.isLoading = false;
                  };

                  if (this.getAuth.bodyprintSearch) {
                    this.$store
                      .dispatch("bodyprint/requestBodyprintInfo", {
                        id: queueId
                      })
                      .then(() => {
                        finishGetViewData();
                      });
                  } else {
                    finishGetViewData();
                  }
                });
              })
              .catch(() => {
                this.toEndScreen();
              });
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.toEndScreen();
        });
    },
    setImageWindowHeight() {
      let payload = {
        height: window.innerHeight,
        width: window.innerWidth
      };
      this.setWindowHeight(payload);
    },
    getPreviousStepText() {
      if (this.current_step === "crop") {
        if (this.current_projection === "back") {
          return "";
        } else if (this.current_projection === "left") {
          return this.$t("bodyprint_edit.buttons.back_crop");
        }
      } else if (this.current_step === "measure") {
        return this.$t("bodyprint_edit.buttons.previous");
      }
    },
    getNextStepText() {
      if (this.current_step === "crop") {
        if (this.current_projection === "back") {
          return this.$t("bodyprint_edit.buttons.side_crop");
        } else if (this.current_projection === "left") {
          return this.$t("bodyprint_edit.buttons.measure");
        }
      } else if (this.current_step === "measure") {
        return this.$t("bodyprint_edit.buttons.next");
      }
    },
    returnToCropping() {
      this.current_projection = "left";
      this.current_step = "crop";
    },
    goToPreviousStep() {
      this.setActiveCoordinates({}); //reset activeCoordinates
      if (this.current_step === "crop") {
        if (this.current_projection === "left") {
          this.current_projection = "back";
        }
      } else if (this.current_step === "measure") {
        this.previousMeasureStep();
        this.updateBodyprint();
      }
    },
    goToNextStep() {
      this.setActiveCoordinates({}); //reset activeCoordinates
      if (this.current_step === "crop") {
        if (this.current_projection === "back") {
          this.current_projection = "left";
        } else if (this.current_projection === "left") {
          this.current_projection = "back";
          this.current_step = "measure";
          this.measure_step = 0;
          this.setStepMeasure();
        }
      } else if (this.current_step === "measure") {
        this.nextMeasureStep();
        this.updateBodyprint();
      }
    },
    nextMeasureStep() {
      this.measure_step = this.getMeasureStep + 1;
      this.setStepMeasure();
    },
    previousMeasureStep() {
      this.measure_step = this.getMeasureStep - 1;
      this.setStepMeasure();
    },
    setStepMeasure() {
      const payload = {
        areas: this.getAreas,
        step: this.measure_step,
        projection: this.current_projection
      };
      this.updateConstraints(payload);
      this.setMeasureStep(payload);
    },
    saveBodyprint() {
      this.postBodyprint().then(() => {
        this.toEndScreen();
      });
    },
    rejectBodyPrint(reason) {
      this.showProblemModal = false;
      var payload = {
        status: "REJECTED",
        rejection_reason: reason
      };
      this.setQueueItemStatus(payload).then(() => this.toEndScreen());
    },
    stopEditing() {
      this.cancelQueueItem().then(() => this.toEndScreen());
    },
    completeBodyPrint() {
      this.postBodyprint().then(() => {
        var payload = { status: "COMPLETED" };
        this.setQueueItemStatus(payload).then(() => this.toEndScreen());
      });
      // this.toEndScreen()
    },
    updateBodyprint() {
      this.postBodyprint().then(() => {
        this.measurementsLoading = true;
        const params = {
          user: this.getBodyPrint.user,
          bodyprint: this.getBodyPrint._id
        };
        setTimeout(() => {
          this.getBodyprintSizes(params).then(() => {
            this.measurementsLoading = false;
          });
        }, 1000);
      });
    },
    toEndScreen() {
      this.$router.replace({
        name: ROUTE_BODYPRINT_QUEUE
      });
    },
    handleKeydown(e) {
      switch (e.code) {
        case "Enter":
          e.preventDefault();
          this.goToNextStep();
          break;
        case "Backspace":
          e.preventDefault();
          this.goToPreviousStep();
          break;
      }
    },
    prepareMeasurementsModal() {
      this.updateBodyprint();
      this.showMeasurementsModal = true;
      this.measurementsBodyprint = this.getBodyPrint._id;
    },
    imageFlippedSet() {
      if (this.getArea.projection === "left") {
        return this.side_image_flipped;
      } else if (this.getArea.projection === "back") {
        return this.back_image_flipped;
      }
      return true;
    }
  }
};
</script>

<style></style>
