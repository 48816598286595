import axios from "axios";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    authenticated: false,
    loggedIn: false,
    userId: null,
    userEmail: null,
    noPermission: false,
    bodyprintQueue: false,
    bodyprintSearch: false,
    bodyprintList: false,
    productionOrders: false,
    authenticationError: false
  },
  getters: {
    getAuth: state => {
      return state;
    },
    getAuthLoggedIn: state => {
      return state.loggedIn;
    }
  },
  actions: {
    authenticate({ commit, rootGetters }, payload) {
      axios
        .get(`${rootGetters["network/getApiUrl"]}/api/v2/authenticate`, {
          headers: { Authorization: `Basic ${payload}` }
        })
        .then(call => {
          if (call.data.status === "ACTIVE") {
            axios
              .get(`${rootGetters["network/getApiUrl"]}/api/v2/permission/me`, {
                headers: { Authorization: `Basic ${payload}` }
              })
              .then(resp => {
                if (resp.data.find(x => x.permission === "measurePortal")) {
                  var bodyprintQueue = resp.data.find(
                    x => x.permission === "measurePortalBodyprintQueue"
                  );
                  var bodyprintSearch = resp.data.find(
                    x => x.permission === "measurePortalBodyprintSearch"
                  );
                  var bodyprintList = resp.data.find(
                    x => x.permission === "measurePortalBodyprintList"
                  );
                  var productionOrders = resp.data.find(
                    x => x.permission === "measurePortalProductionOrders"
                  );
                  axios
                    .get(
                      `${rootGetters["network/getApiUrl"]}/api/v2/user/${call.data._id}`,
                      {
                        headers: { Authorization: `Basic ${payload}` }
                      }
                    )
                    .then(user => {
                      commit("LOGIN", {
                        token: payload,
                        user: user,
                        result: call,
                        bodyprintQueue: bodyprintQueue,
                        bodyprintSearch: bodyprintSearch,
                        bodyprintList: bodyprintList,
                        productionOrders: productionOrders
                      });
                      commit(
                        "network/configureClient",
                        {
                          token: `${payload}`
                        },
                        { root: true }
                      );
                    });
                } else {
                  commit("NOPERMISSION");
                }
              });
          }
        })
        .catch(() => {
          commit("AUTHENTICATIONERROR", true);
        });
    },
    authCheck({ commit, rootGetters }) {
      commit("CHECK", { apiUrl: rootGetters["network/getApiUrl"] });
      if (localStorage.getItem("auth_token")) {
        commit(
          "network/configureClient",
          {
            token: `${localStorage.getItem("auth_token")}`
          },
          { root: true }
        );
      }
    },
    authLogout({ commit, state }) {
      (state.bodyprintSizes = []), (state.bodyprints = []);
      state.bodyprint = {};

      commit(
        "network/configureClient",
        {
          token: ""
        },
        { root: true }
      );
      commit("LOGOUT");
    },
    requestPasswordReset({ rootGetters }, username) {
      axios.get(
        `${rootGetters["network/getApiUrl"]}/api/v2/user/${username}/request_password_reset_v2`
      );
    },
    resetPassword({ rootGetters }, payload) {
      axios.post(
        `${rootGetters["network/getApiUrl"]}/api/v2/user/${payload.email}/reset_password`,
        {
          password: payload.password,
          token: payload.code
        }
      );
    }
  },
  mutations: {
    CHECK(state) {
      state.authenticated = !!localStorage.getItem("auth_token");
      if (state.authenticated) {
        state.loggedIn = true;
        state.userId = localStorage.getItem("auth_userid");
        state.userEmail = localStorage.getItem("auth_useremail");
        state.bodyprintQueue =
          localStorage.getItem("auth_bodyprintQueue") === "true";
        state.bodyprintSearch =
          localStorage.getItem("auth_bodyprintSearch") === "true";
        state.bodyprintList =
          localStorage.getItem("auth_bodyprintList") === "true";
        state.productionOrders =
          localStorage.getItem("auth_productionOrders") === "true";
      }
    },
    LOGIN(state, payload) {
      state.loggedIn = true;
      state.userId = payload.result.data._id;
      state.userEmail = payload.user.data.email;
      state.bodyprintQueue = !!payload.bodyprintQueue;
      state.bodyprintSearch = !!payload.bodyprintSearch;
      state.bodyprintList = !!payload.bodyprintList;
      state.productionOrders = !!payload.productionOrders;
      state.authenticationError = false;
      localStorage.setItem("auth_token", payload.token);
      localStorage.setItem("auth_bodyprintQueue", state.bodyprintQueue);
      localStorage.setItem("auth_bodyprintSearch", state.bodyprintSearch);
      localStorage.setItem("auth_bodyprintList", state.bodyprintList);
      localStorage.setItem("auth_productionOrders", state.productionOrders);
      localStorage.setItem("auth_userid", state.userId);
      localStorage.setItem("auth_useremail", state.userEmail);

      axios.defaults.headers.common.Authorization = `Basic ${payload.token}`;
      axios.defaults.baseURL = `${this.getters.getApiUrl}/api/v2/`;
      axios.defaults.headers.common["X-DOMAIN"] = window.location.host;
      Vue.prototype.$http = axios;
    },
    LOGOUT(state) {
      (state.bodyprintSizes = []), (state.bodyprints = []);
      state.bodyprint = {};
      state.loggedIn = false;
      state.userId = false;
      state.userEmail = false;
      state.bodyprintQueue = false;
      state.bodyprintSearch = false;
      state.bodyprintList = false;
      state.productionOrders = false;
      localStorage.removeItem("auth_token");
      localStorage.removeItem("auth_bodyprintQueue");
      localStorage.removeItem("auth_bodyprintSearch");
      localStorage.removeItem("auth_bodyprintList");
      localStorage.removeItem("auth_productionOrders");
      localStorage.removeItem("auth_userid");
      localStorage.removeItem("auth_useremail");

      axios.defaults.headers.common.Authorization = "";
      Vue.prototype.$http = axios;
    },
    NOPERMISSION(state) {
      state.noPermission = true;
    },
    AUTHENTICATIONERROR(state, payload) {
      state.authenticationError = payload;
    }
  }
};
