import { Vector, VectorToFrom, VectorPoint } from "@/models/vector";

export class Plane {
  constructor(point, planarVectorA, planarVectorB) {
    // var plane = new Plane(point, (planarVectorA.crossProduct(planarVectorB)));
    var vec = planarVectorA.crossProduct(planarVectorB);
    this.a = vec.dx;
    this.b = vec.dy;
    this.c = vec.dz;
    this.d = -vec.dotProduct(new VectorPoint(point));
  }
  setLength() {
    this.length = Math.sqrt(
      Math.pow(this.dx, 2) + Math.pow(this.dy, 2) + Math.pow(this.dz, 2)
    );
  }
  planeIntersect(vector, point) {
    var normalVector = new Vector(this.a, this.b, this.c);
    var vectorProduct = normalVector.dotProduct(vector);
    var t =
      -(normalVector.dotProduct(new VectorPoint(point)) + this.d) /
      vectorProduct;
    return {
      name: "",
      x: point.x + t * vector.dx,
      y: point.y + t * vector.dy,
      z: point.z + t * vector.dz
    };
  }
  planeIntersectPoints(startPoint, endPoint) {
    var lineVector = new VectorToFrom(startPoint, endPoint);
    return this.planeIntersect(lineVector, startPoint);
  }
}
