<template>
  <div>
    <template v-for="point in setPoints" :style="point.style">
      <measure-draggable
        :key="`${getArea.projection}-${point.pointNumber}`"
        :point="point"
        @onEnd="calculatePosition"
        :flipped="flipped"
      />
    </template>
  </div>
</template>

<script>
import controlMixin from "../control-mixin";
import MeasureDraggable from "./measure-draggable.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "MeasureSetPoint",
  components: {
    MeasureDraggable
  },
  mixins: [controlMixin],
  props: {
    cropStyle: {
      type: Object
    },
    offset: {
      type: Object
    },
    areaZoom: {
      type: Number
    },
    cropWidth: {
      type: Number
    },
    cropHeight: {
      type: Number
    },
    projection: {
      type: String
    },
    flipped: {
      type: Boolean
    }
  },
  data() {
    return {
      points: []
    };
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeydown, null);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeydown);
  },
  computed: {
    ...mapGetters({
      getPointsByArea: "configuration/getPointsByArea",
      getGuide: "bodyprint/getGuide",
      getArea: "configuration/getArea",
      getPoint: "configuration/getPoint",
      getNewCoordinates: "bodyprint/getNewCoordinates"
    }),
    setPoints() {
      let points = [];
      for (var i = 0; i < this.getPointsByArea.length; i++) {
        const currentHandle = this.getArea.handles[i];
        var pointCoordinates = this.coordinates(this.getPointsByArea[i]);
        points.push({
          area: this.getArea,
          pointNumber: this.getPointsByArea[i].puppetPointName,
          x: pointCoordinates.x,
          y: pointCoordinates.y,
          canMoveX: currentHandle.axis.x || currentHandle.axis.z,
          canMoveY: currentHandle.axis.y,
          handle: currentHandle
        });
      }
      return points;
    }
  },
  methods: {
    ...mapMutations({ deactivatePoints: "bodyprint/deactivatePoints" }),
    coordinates(point) {
      return {
        name: point.name,
        y:
          this.cropHeight * point.coordinates.y +
          this.cropStyle.marginTop * this.areaZoom +
          this.offset.marginTop,
        x:
          this.cropWidth * point.coordinates.x +
          this.cropStyle.marginLeft * this.areaZoom +
          this.offset.marginLeft
      };
    },
    calculatePosition(pointNumber, coordinates) {
      const x =
        (coordinates.x -
          this.cropStyle.marginLeft * this.areaZoom -
          this.offset.marginLeft) /
        this.cropWidth;
      const y =
        (coordinates.y -
          this.cropStyle.marginTop * this.areaZoom -
          this.offset.marginTop) /
        this.cropHeight;

      const currentPoint = this.getPoint(pointNumber);
      const mutation = {
        projection: this.projection,
        name: `${pointNumber}`,
        y: y,
        x: this.getArea.projection === "back" ? x : currentPoint.x,
        z: this.getArea.projection === "left" ? x : currentPoint.z
      };
      this.$store.dispatch("bodyprint/setPoint", mutation);

      // if (pointNumber === "point_0") {
      setTimeout(() => {
        this.$store.commit("bodyprint/updateConstraints", {
          projection: this.getArea.projection
        });
        const updatedPoint = this.coordinates(
          this.getPointsByArea.find(
            areaPoint => areaPoint.puppetPointName === pointNumber
          )
        );
        const activePoint = this.getNewCoordinates;
        activePoint.x = updatedPoint.x;
        activePoint.y = updatedPoint.y;
        this.setActiveCoordinates({});
        this.setActive(activePoint);
      }, 200);
      //}
    },
    handleKeydown(e) {
      switch (e.code) {
        case "Space":
          e.preventDefault();
          if (this.getNewCoordinates.pointNumber) {
            this.deactivatePoints();
          } else {
            this.setActive(this.setPoints[0]);
          }
          break;
        case "Tab":
          e.preventDefault();
          if (!this.getNewCoordinates.pointNumber) {
            this.setActive(this.setPoints[0]);
          } else {
            var currentIndex = this.setPoints.findIndex(
              p => p.pointNumber === this.getNewCoordinates.pointNumber
            );
            var newIndex = currentIndex;
            if (currentIndex === this.setPoints.length - 1) {
              newIndex = 0;
            } else {
              newIndex++;
            }
            this.setActive(this.setPoints[newIndex]);
          }
          break;
      }
    }
  }
};
</script>

<style></style>
