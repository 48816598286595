<template>
  <div class="bg-white py-4 px-6">
    <div class="text-xl font-semibold text-blue-800 mb-2">
      {{ $t("bodyprint_info.header") }}
    </div>
    <div class="w-full h-1 bg-gray-400" />
    <div class="grid grid-cols-7 py-2 break-words">
      <p class="col-span-3 font-bold">
        {{ $t("bodyprint_info.data.labels.user") }}
      </p>
      <p class="col-span-4">{{ getBodyprintInfo.user.name }}</p>
      <p class="col-span-3 font-bold">
        {{ $t("bodyprint_info.data.labels.email") }}
      </p>
      <p class="col-span-4">{{ getBodyprintInfo.user.email }}</p>
      <p class="col-span-3 font-bold">
        {{ $t("bodyprint_info.data.labels.user_permission") }}
      </p>
      <p class="col-span-4">{{ getBodyprintInfo.user.permission }}</p>
      <p class="col-span-3 font-bold">
        {{ $t("bodyprint_info.data.labels.retailer_code") }}
      </p>
      <p class="col-span-4">{{ getBodyprintInfo.retailer_code }}</p>
      <p class="col-span-3 font-bold">
        {{ $t("bodyprint_info.data.labels.created_at") }}
      </p>
      <p class="col-span-4">{{ getBodyprintInfo.created_at }}</p>
      <p class="col-span-3 font-bold">
        {{ $t("bodyprint_info.data.labels.last_updated_at") }}
      </p>
      <p class="col-span-4">{{ getBodyprintInfo.last_updated_at }}</p>
      <p class="col-span-3 font-bold">
        {{ $t("bodyprint_info.data.labels.last_editor") }}
      </p>
      <p class="col-span-4">{{ getBodyprintInfo.last_editor }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  computed: {
    ...mapGetters({
      getBodyprintInfo: "bodyprint/getBodyPrintInfo"
    })
  },
  props: {},
  data() {
    return {};
  }
};
</script>

<style></style>
