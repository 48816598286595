import DreszcodeConfiguration from "@/assets/json/DreszcodeConfiguration.json";

export default {
  namespaced: true,
  state: {
    defaultPoints: DreszcodeConfiguration["initialPoints"],
    lines: DreszcodeConfiguration["lines"],
    intersections: DreszcodeConfiguration["intersections"],
    areas: DreszcodeConfiguration["areas"],
    constraints: DreszcodeConfiguration["constraints"]
  },
  getters: {
    getOrderPoint: (state, getters, rootState) => name => {
      let point = rootState.retailerOrders.order.queue_item.bodyprint.points.find(
        point => point.name === name
      );
      if (point) {
        return {
          name: point.name,
          y: point.y,
          x: point.x,
          z: point.z
        };
      }
      return state.defaultPoints.find(point => point.name === name);
    },
    getPoint: (state, getters, rootState) => name => {
      let point = rootState.bodyprint.bodyprint.points.find(
        point => point.name === name
      );
      if (point) {
        return {
          name: point.name,
          y: point.y,
          x: point.x,
          z: point.z
        };
      }
      return state.defaultPoints.find(point => point.name === name);
    },
    getPointCoordinates: (state, getters) => (name, projection) => {
      const point = getters.getPoint(name);
      // normalize here...
      return {
        y: point.y,
        x: projection === "back" ? point.x : point.z
      };
    },
    getLines: state => {
      return state.lines;
    },
    getAreas: state => {
      return state.areas;
    },
    getArea: (state, getters, rootState) => {
      const step = rootState.bodyprint.measureStep;
      return state.areas[step];
    },
    getAreaLength: state => {
      return state.areas.length;
    },
    getPointsByArea: (state, getters) => {
      const area = getters.getArea;
      let pointCoordinates = [];
      for (let i = 0; i < area.handles.length; i++) {
        pointCoordinates.push({
          side: area.projection,
          name: area.handles[i].name,
          puppetPointName: area.handles[i].puppetPointName,
          coordinates: getters.getPointCoordinates(
            area.handles[i].puppetPointName,
            area.projection
          )
        });
      }
      return pointCoordinates;
    }
  },
  actions: {},
  mutations: {}
};
