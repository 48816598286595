<template>
  <div :style="stepContainer">
    <div class="w-full flex justify-center">
      <!-- <button class="button-step" @click="previousStep">←</button> -->
      <span class>{{ getMeasureStep + 1 }} / {{ getAreaLength }}</span>
      <!-- <button class="button-step" @click="nextStep">→</button> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "MeasureSteps",
  data() {
    return {
      step: 0
    };
  },
  props: {
    projection: String
  },
  computed: {
    ...mapGetters({
      getPictureHeight: "bodyprint/getPictureHeight",
      getMeasureStep: "bodyprint/getMeasureStep",
      getAreaLength: "configuration/getAreaLength"
    }),
    stepContainer() {
      return {
        height: "50px",
        backgroundColor: "white",
        position: "absolute",
        width: this.getPictureHeight * 0.6 + "px"
      };
    }
  },
  methods: {
    ...mapMutations({
      setMeasureStep: "bodyprint/setMeasureStep",
      updateConstraints: "bodyprint/updateConstraints"
    }),
    nextStep() {
      //length
      this.step = this.getMeasureStep + 1;
      this.setStep();
    },
    previousStep() {
      this.step = this.getMeasureStep - 1;
      this.setStep();
    },
    setStep() {
      const payload = {
        step: this.step,
        projection: this.projection
      };
      this.updateConstraints(payload);
      this.setMeasureStep(payload);
    }
  }
};
</script>
