import Vue from "vue";
import Vuex from "vuex";
import layout from "@/store/modules/layout";
import auth from "@/store/modules/auth";
import configuration from "@/store/modules/configuration";
import network from "@/store/modules/network";
import application from "@/store/modules/application";
import bodyprint from "@/store/modules/bodyprint";
import retailerOrders from "@/store/modules/retailerOrders";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    application,
    auth,
    bodyprint,
    configuration,
    layout,
    network,
    retailerOrders
  }
});

export default store;
