<template>
  <div>
    <div class="flex min-h-screen max-h-screen flex-col">
      <div class="flex mx-auto p-4 w-full justify-center">
        <p>{{ $t("index.navigate") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("layout/toggleSidebar");
  }
};
</script>
