<template>
  <line
    :x1="startCoordinates.x"
    :y1="startCoordinates.y"
    :x2="endCoordinates.x"
    :y2="endCoordinates.y"
    :stroke-dasharray="4"
    :style="[
      isActive
        ? { stroke: 'rgb(255, 255, 255)', 'stroke-width': '2' }
        : { stroke: 'rgb(255, 153, 0)', 'stroke-width': '2' }
    ]"
  />
</template>

<script>
import { mapGetters } from "vuex";

export default {
  //  style="stroke:rgb(255, 153, 0);stroke-width:2"
  //style="[isActive ? {'stroke': 'rgb(255, 255, 255);', 'stroke-width': '2'} : {'stroke': 'rgb(255, 153, 0);', 'stroke-width': '2'}]"
  name: "BodyprintSkeletonLine",
  props: {
    projection: String,
    startPoint: String,
    endPoint: String,
    crop: Object,
    width: Number,
    height: Number,
    zoom: Number,
    heightZoom: Number,
    isActive: Boolean
  },
  computed: {
    ...mapGetters({
      getPoint: "configuration/getPoint",
      getOrderPoint: "configuration/getOrderPoint"
    }),
    startCoordinates() {
      let point = this.pointToUse(this.startPoint);
      return {
        y: point.y * this.height * this.heightZoom,
        x:
          this.projection === "back"
            ? point.x * this.width * this.crop.width * this.zoom
            : point.z * this.width * this.crop.width * this.zoom
      };
    },
    endCoordinates() {
      let point = this.pointToUse(this.endPoint);
      return {
        y: point.y * this.height * this.heightZoom,
        x:
          this.projection === "back"
            ? point.x * this.width * this.crop.width * this.zoom
            : point.z * this.width * this.crop.width * this.zoom
      };
    }
  },
  methods: {
    pointToUse(name) {
      try {
        return this.getPoint(name, this.projection);
      } catch (e) {
        return this.getOrderPoint(name, this.projection);
      }
    }
  }
};
</script>
