<template>
  <div
    id="pointer"
    :style="style"
    :class="`point-${point.area.projection}-${point.pointNumber}`"
  >
    <div v-if="isActive && point.handle.contour" :style="contour"></div>
    <div v-if="isActive" id="cross"></div>
    <img
      :key="point.index"
      :src="getHandleIcon.src"
      :style="getHandleIcon.style"
      @click="setActiveState(point)"
      alt="Point handle"
    />
  </div>
</template>

<script>
import controlMixin from "../control-mixin";
import interact from "interactjs";
import { mapState, mapGetters, mapMutations } from "vuex";
import { BodyprintMode } from "@/enums";

export default {
  name: "MeasureDraggable",
  props: {
    point: {
      type: Object,
      required: true
    },
    flipped: {
      type: Boolean
    }
  },
  mixins: [controlMixin],
  data() {
    return {
      interactPosition: {
        x: this.point.x,
        y: this.point.y
      }
    };
  },
  watch: {
    newCoordinates: {
      handler(value) {
        this.interactPosition.x = this.point.x;
        this.interactPosition.y = value.y;
        this.$emit("onEnd", value.pointNumber, value);
      },
      deep: true
    },
    point() {
      this.interactPosition.x = this.point.x;
      this.interactPosition.y = this.point.y;
    }
  },
  mounted() {
    //window.addEventListener('keydown', this.handleKeydown, null);
    if (this.getBodyprintMode !== BodyprintMode.VIEW) {
      interact(
        `.point-${this.point.area.projection}-${this.point.pointNumber}`
      ).draggable({
        inertia: true,
        onmove: event => {
          this.setActive(this.point);
          this.getNewCoordinates.pointNumber = this.point.pointNumber; // for magnifer
          const x = this.point.canMoveX
            ? this.interactPosition.x + event.dx
            : this.interactPosition.x;
          const y = this.point.canMoveY
            ? this.interactPosition.y + event.dy
            : this.interactPosition.y;
          this.interactSetPosition({ x, y });
          this.interactEndPosition();
          this.checkReCrop();
        },
        onend: () => {
          this.interactEndPosition();
        }
      });
    }
  },
  computed: {
    ...mapState(["newCoordinates"]),
    ...mapGetters({
      getMeasureStep: "bodyprint/getMeasureStep",
      getNewCoordinates: "bodyprint/getNewCoordinates",
      getMeasureImage: "bodyprint/getMeasureImage",
      getImage: "bodyprint/getImage",
      getMagnifier: "bodyprint/getMagnifier",
      getBodyprintMode: "bodyprint/getBodyprintMode"
    }),
    isActive() {
      return this.getNewCoordinates.pointNumber === this.point.pointNumber;
    }, // active one has magnifer
    getHandleIcon() {
      var payload = {};
      if (this.isActive) {
        if (this.point.area.projection === "back") {
          payload.src = this.getImage("back");
        } else {
          payload.src = this.getImage("left");
        }

        let x =
          (Number(this.getMeasureImage.marginLeft.replace(/[^\d.-]/g, "")) -
            this.interactPosition.x) *
            this.point.handle.magnification +
          75;
        let width =
          Number(this.getMeasureImage.width.replace(/[^\d.-]/g, "")) *
          this.point.handle.magnification;
        let y =
          (Number(this.getMeasureImage.marginTop.replace(/[^\d.-]/g, "")) -
            this.interactPosition.y) *
            this.point.handle.magnification +
          75;
        let height =
          Number(this.getMeasureImage.height.replace(/[^\d.-]/g, "")) *
          this.point.handle.magnification;

        payload.style = `width:${width}px; height:${height}px; margin-left:${x}px; margin-top:${y}px; transform:${
          this.flipped ? "scaleX(-1)" : "scaleX(1)"
        }`;
        return payload;
      } else {
        let handle = "";
        if (this.point.canMoveX) {
          handle += "X";
        }
        if (this.point.canMoveY) {
          handle += "Y";
        }
        payload.src = require(`../../assets/marker${handle}.png`);
        payload.style = "width: 50px; margin-left: -25px;";
        return payload;
      }
    },
    contour() {
      return {
        zIndex: 99,
        position: "absolute",
        height: "150px",
        width: "150px",
        backgroundImage: `
          linear-gradient(${this.point.handle.contour.endAngle}deg, transparent 50%, rgb(0 0 0 / 0.3) 50%),
          linear-gradient(${this.point.handle.contour.startAngle}deg, rgb(0 0 0 / 0.2) 50%, transparent 50%)
        `
      };
    },
    style() {
      if (this.getNewCoordinates.pointNumber === this.point.pointNumber) {
        return {
          left: this.interactPosition.x + "px",
          top: this.interactPosition.y + "px",
          marginLeft: "-75px",
          marginTop: "-75px",
          position: "absolute",
          border: "2px solid red",
          height: "150px",
          borderRadius: "50%",
          width: "150px",
          overflow: "hidden"
        };
      } else {
        return {
          left: this.interactPosition.x + "px",
          top: this.interactPosition.y + "px",
          position: "absolute",
          "max-height": "50px",
          "touch-action": "none"
        };
      }
    }
  },
  methods: {
    ...mapMutations({ updateConstraints: "bodyprint/updateConstraints" }),
    interactSetPosition(coordinates) {
      const { x = 0, y = 0 } = coordinates;
      this.interactPosition = { x, y };
    },
    interactEndPosition() {
      this.$emit("onEnd", this.point.pointNumber, this.interactPosition);
    },
    // handleKeydown (e) {
    // 	switch (e.code) {
    //     case "ArrowRight":
    //      e.preventDefault();
    //      this.control('right', 1, 0);
    //      break;
    //     case "ArrowLeft":
    //      this.control('left', -1, 0);
    //      e.preventDefault();
    //      break;
    //     case "ArrowUp":
    //      this.control('top', 0, -1);
    //      e.preventDefault();
    //      break;
    //     case "ArrowDown":
    //      this.control('bottom', 0, 1);
    //      e.preventDefault();
    //      break;
    //   }
    // },
    // control (direction, move_x, move_y) {
    //   this.setActive(this.point);
    //     this.getNewCoordinates.pointNumber = this.point.pointNumber; // for magnifer
    //     const x = this.point.canMoveX
    //       ? this.interactPosition.x + move_x
    //       : this.interactPosition.x;
    //     const y = this.point.canMoveY
    //       ? this.interactPosition.y + move_y
    //       : this.interactPosition.y;
    //     this.interactSetPosition({ x, y });
    //     this.interactEndPosition();
    //     this.checkReCrop();
    //     this.interactSetPosition({ x, y });
    //     this.interactEndPosition();
    // },
    checkReCrop() {
      if (
        this.point.pointNumber === "point_38" ||
        this.point.pointNumber === "point_0"
      ) {
        let payload = {
          projection: this.point.area.projection,
          step: this.getMeasureStep
        };
        this.updateConstraints(payload);
      }
    },
    setActiveState(point) {
      if (this.getBodyprintMode !== BodyprintMode.VIEW) {
        this.setActive(point);
      }
    }
  }
};
</script>
<style scoped>
#cross {
  background: red;
  height: 200px;
  position: absolute;
  width: 1px;
  left: 73px;
  z-index: 100;
}
#cross:after {
  background: red;
  content: "";
  height: 1px;
  left: -100px;
  position: absolute;
  top: 73px;
  width: 200px;
  z-index: 100;
}
</style>
