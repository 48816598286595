<template>
  <svg :style="svgStyle">
    <bodyprint-skeleton-line
      :crop="crop"
      v-for="(line, index) in getLines"
      :key="index"
      :startPoint="line.startPointName"
      :endPoint="line.endPointName"
      :projection="projection"
      :zoom="zoom"
      :heightZoom="heightZoom"
      :width="width"
      :height="height"
      :isActive="activeLine(line)"
    />
  </svg>
</template>

<script>
import { mapGetters } from "vuex";
import BodyprintSkeletonLine from "./bodyprint-skeleton-line.vue";

export default {
  components: {
    BodyprintSkeletonLine
  },
  props: {
    projection: String,
    crop: Object,
    zoom: Number,
    heightZoom: Number,
    addTopZero: {
      type: Boolean,
      default: true
    },
    height: {
      type: Number,
      default: 750
    },
    width: {
      type: Number,
      default: 750
    },
    showActiveLine: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      getLines: "configuration/getLines",
      getArea: "configuration/getArea"
    }),
    svgStyle() {
      return {
        height: this.height * this.heightZoom + "px",
        top: this.addTopZero ? "0px" : "",
        width: this.width * this.heightZoom + "px"
      };
    }
  },
  methods: {
    activeLine(line) {
      if (!this.showActiveLine) return false;
      return this.getArea.handles.some(
        activePoint =>
          activePoint.puppetPointName === line.startPointName ||
          activePoint.puppetPointName === line.endPointName
      );
    }
  }
};
</script>
