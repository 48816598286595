var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isActive)?_c('div',{staticClass:"crop-active-overlay",on:{"click":_vm.onClickOutsideCrop}},[_c('crop-bar',{attrs:{"bar-position":"top","start-position":{
        x: ((_vm.crop.x + _vm.crop.x2) / 2) * _vm.picture_width,
        y: _vm.crop.y * _vm.picture_height
      },"length":_vm.width * _vm.picture_width,"position":"vertical","projection":_vm.projection,"active":_vm.selectedBar === 'top',"show":_vm.selectedBar === '' || _vm.selectedBar === 'top',"flipped":_vm.flipped},on:{"onStart":_vm.onCropStart,"onChange":_vm.onCropChange,"onEnd":_vm.onCropEnd}}),_c('crop-bar',{attrs:{"bar-position":"bottom","start-position":{
        x: ((_vm.crop.x + _vm.crop.x2) / 2) * _vm.picture_width,
        y: _vm.crop.y2 * _vm.picture_height
      },"length":_vm.width * _vm.picture_width,"position":"vertical","projection":_vm.projection,"active":_vm.selectedBar === 'bottom',"show":_vm.selectedBar === '' || _vm.selectedBar === 'bottom',"flipped":_vm.flipped},on:{"onStart":_vm.onCropStart,"onChange":_vm.onCropChange,"onEnd":_vm.onCropEnd}}),_c('crop-bar',{attrs:{"bar-position":"left","start-position":{
        x: _vm.crop.x * _vm.picture_width,
        y: ((_vm.crop.y + _vm.crop.y2) / 2) * _vm.picture_height
      },"length":_vm.height * _vm.picture_height,"position":"horizontal","projection":_vm.projection,"active":_vm.selectedBar === 'left',"show":_vm.selectedBar === '' || _vm.selectedBar === 'left',"flipped":_vm.flipped},on:{"onStart":_vm.onCropStart,"onChange":_vm.onCropChange,"onEnd":_vm.onCropEnd}}),_c('crop-bar',{attrs:{"bar-position":"right","start-position":{
        x: _vm.crop.x2 * _vm.picture_width,
        y: ((_vm.crop.y + _vm.crop.y2) / 2) * _vm.picture_height
      },"length":_vm.height * _vm.picture_height,"position":"horizontal","projection":_vm.projection,"active":_vm.selectedBar === 'right',"show":_vm.selectedBar === '' || _vm.selectedBar === 'right',"flipped":_vm.flipped},on:{"onStart":_vm.onCropStart,"onChange":_vm.onCropChange,"onEnd":_vm.onCropEnd}}),_c('div',{class:_vm.selectedBar === '' ? 'outside-non-selected' : 'outside-selected',style:(_vm.topOverlayStyle)}),_c('div',{class:_vm.selectedBar === '' ? 'outside-non-selected' : 'outside-selected',style:(_vm.bottomOverlayStyle)}),_c('div',{class:_vm.selectedBar === '' ? 'outside-non-selected' : 'outside-selected',style:(_vm.leftOverlayStyle)}),_c('div',{class:_vm.selectedBar === '' ? 'outside-non-selected' : 'outside-selected',style:(_vm.rightOverlayStyle)}),_c('svg',{staticClass:"absolute z-10",attrs:{"height":_vm.picture_height,"width":_vm.picture_width}},[_c('rect',{style:(_vm.selectedBar === ''
            ? 'fill:rgba(0, 0, 0, 0.0)'
            : 'fill:rgba(0, 0, 0, 0.6)'),attrs:{"x":"0","y":"0","width":_vm.picture_width,"height":_vm.picture_height}}),_c('line',{staticStyle:{"stroke":"rgb(255,127,80)","stroke-width":"2"},attrs:{"x1":_vm.leftOverlayStyle.width,"y1":"","x2":_vm.leftOverlayStyle.width,"y2":_vm.picture_height}}),_c('line',{staticStyle:{"stroke":"rgb(255,127,80)","stroke-width":"2"},attrs:{"x1":_vm.rightOverlayStyle.left,"y1":"","x2":_vm.rightOverlayStyle.left,"y2":_vm.picture_height}}),_c('line',{staticStyle:{"stroke":"rgb(255,127,80)","stroke-width":"2"},attrs:{"y1":_vm.topOverlayStyle.height,"y2":_vm.topOverlayStyle.height,"x2":_vm.picture_width}}),_c('line',{staticStyle:{"stroke":"rgb(255,127,80)","stroke-width":"2"},attrs:{"y1":_vm.bottomOverlayStyle.top,"y2":_vm.bottomOverlayStyle.top,"x2":_vm.picture_width}})])],1):_vm._e(),(!_vm.isActive)?_c('div',{staticClass:"crop-inactive-overlay"}):_vm._e(),_c('img',{staticClass:"w-auto h-full",style:(_vm.imageStyle),attrs:{"src":_vm.imageSource,"alt":"Projection image"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }