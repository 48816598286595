<template>
  <div
    class="bg-white py-4 px-6"
    v-if="instruction.name && instruction.image && instruction.text"
  >
    <p class="text-xl font-semibold text-blue-800">{{ instruction.name }}</p>
    <div class="w-full h-1 bg-gray-400" />
    <div class="w-full flex justify-center mt-2">
      <img
        class="w-auto h-full instruction-image"
        :src="instruction.image"
        alt="Crop instruction image"
      />
      <div class="w-full ml-5">
        <p>{{ instruction.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import controlMixin from "../control-mixin";
import { mapGetters, mapState } from "vuex";

export default {
  name: "CropInstructions",
  props: {
    projection: {
      type: String
    }
  },
  components: {},
  mixins: [controlMixin],
  data() {
    return {
      instruction: {
        name: null,
        image: null,
        text: null
      }
    };
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  computed: {
    ...mapState({
      newCoordinates: "bodyprint/newCoordinates",
      activeCoordinates: "bodyprint/activeCoordinates"
    }),
    ...mapGetters({ getNewCoordinates: "bodyprint/getNewCoordinates" })
  },
  methods: {
    getInstructionInformation() {
      const position = this.getNewCoordinates.position;

      var newInstruction = {
        name: null,
        image: null,
        text: null
      };

      if (position !== undefined) {
        switch (position) {
          // Top
          case "top":
            newInstruction.name = this.$t("crop_instructions.top.name");
            if (this.projection === "back") {
              newInstruction.image = require(`../../assets/crop/crop_info_back_head.png`);
              newInstruction.text = this.$t("crop_instructions.top.back_text");
            } else if (this.projection === "left") {
              newInstruction.image = require(`../../assets/crop/crop_info_left_head.png`);
              newInstruction.text = this.$t("crop_instructions.top.left_text");
            }
            break;
          case "right":
            newInstruction.name = this.$t("crop_instructions.right.name");
            if (this.projection === "back") {
              newInstruction.image = require(`../../assets/crop/crop_info_back_right.png`);
              newInstruction.text = this.$t(
                "crop_instructions.right.back_text"
              );
            } else if (this.projection === "left") {
              newInstruction.image = require(`../../assets/crop/crop_info_left_right.png`);
              newInstruction.text = this.$t(
                "crop_instructions.right.left_text"
              );
            }
            break;
          case "bottom":
            newInstruction.name = this.$t("crop_instructions.bottom.name");
            if (this.projection === "back") {
              newInstruction.image = require(`../../assets/crop/crop_info_back_feet.png`);
              newInstruction.text = this.$t(
                "crop_instructions.bottom.back_text"
              );
            } else if (this.projection === "left") {
              newInstruction.image = require(`../../assets/crop/crop_info_left_feet.gif`);
              newInstruction.text = this.$t(
                "crop_instructions.bottom.left_text"
              );
            }
            break;
          case "left":
            newInstruction.name = this.$t("crop_instructions.left.name");
            if (this.projection === "back") {
              newInstruction.image = require(`../../assets/crop/crop_info_back_left.png`);
              newInstruction.text = this.$t("crop_instructions.left.back_text");
            } else if (this.projection === "left") {
              newInstruction.image = require(`../../assets/crop/crop_info_left_left.png`);
              newInstruction.text = this.$t("crop_instructions.left.left_text");
            }
            break;
          default:
            newInstruction.name = null;
            newInstruction.image = null;
            newInstruction.text = null;
            break;
        }
      }

      this.instruction = newInstruction;
    }
  },
  watch: {
    activeCoordinates: {
      handler() {
        this.getInstructionInformation();
      },
      deep: true
    }
  }
};
</script>

<style>
.instruction-image {
  height: 200px !important;
  border: 2px solid black;
}
</style>
