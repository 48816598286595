export class Vector {
  constructor(x, y, z) {
    this.dx = x;
    this.dy = y;
    this.dz = z;
    this.setLength();
  }
  static VectorToFrom(toPoint, fromPoint) {
    return new Vector(
      toPoint.x - fromPoint.x,
      toPoint.y - fromPoint.y,
      toPoint.z - fromPoint.z
    );
  }
  static VectorPoint(point) {
    return new Vector(point.x, point.y, point.z);
  }
  setLength() {
    this.length = Math.sqrt(
      Math.pow(this.dx, 2) + Math.pow(this.dy, 2) + Math.pow(this.dz, 2)
    );
  }
  getLength() {
    return this.length;
  }
  dotProduct(vector) {
    return this.dx * vector.dx + this.dy * vector.dy + this.dz * vector.dz;
  }
  crossProduct(otherVector) {
    return new Vector(
      this.dy * otherVector.dz - this.dz * otherVector.dy,
      this.dz * otherVector.dx - this.dx * otherVector.dz,
      this.dx * otherVector.dy - this.dy * otherVector.dx
    );
  }
}

export function VectorToFrom(toPoint, fromPoint) {
  return new Vector(
    toPoint.x - fromPoint.x,
    toPoint.y - fromPoint.y,
    toPoint.z - fromPoint.z
  );
}

export function VectorPoint(point) {
  return new Vector(point.x, point.y, point.z);
}
