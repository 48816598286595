<template>
  <div id="app" class="flex flex-col min-h-screen">
    <div class="grid grid-cols-12 min-h-screen">
      <div
        v-bind:class="[
          getAuthLoggedIn && getSidebarOpen ? 'col-span-2' : 'hidden'
        ]"
      >
        <sidebar />
      </div>
      <div
        v-bind:class="[
          getAuthLoggedIn
            ? getSidebarOpen
              ? 'col-span-10'
              : 'col-span-12'
            : 'col-span-12'
        ]"
      >
        <top-bar />
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import TopBar from "@/components/layout/top-bar.vue";
import Sidebar from "@/components/layout/sidebar.vue";

export default {
  name: "App",
  components: {
    Sidebar,
    TopBar
  },
  computed: {
    ...mapGetters({
      getAuthLoggedIn: "auth/getAuthLoggedIn",
      getAuth: "auth/getAuth",
      getSidebarOpen: "layout/getSidebarOpen"
    }),
    ...mapActions(["auth/authCheck"])
  },
  data() {
    return {};
  },
  methods: {},
  created() {
    if (this.$route.query.token) {
      this.$store.dispatch("auth/authenticate", this.$route.query.token);
    }

    this.$store.dispatch("auth/authCheck");
  },
  watch: {}
};
</script>
