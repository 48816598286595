import { BodyprintMode } from "@/enums";
import Vue from "vue";
import { VectorToFrom } from "@/models/vector";
import { Plane } from "@/models/plane";
import { mapGetters } from "vuex";

export default {
  namespaced: true,
  state: {
    loading: true,
    mode: BodyprintMode.NONE,
    zoom: {},
    queueItem: {},
    queueEmpty: false,
    magnifier: true,
    imageSide: null,
    imageBack: null,
    measureImage: {},
    measureStep: 0,
    growthFactor: "",
    modifiedPoints: [],
    activeCoordinates: {},
    activePoints: {},
    newCoordinates: {},
    windowHeight: window.innerHeight - 120,
    imageHeight: 1000,
    imageWidth: 750,
    queueSize: 0,
    isQueueItem: false,
    queue: {},
    currentStep: "review",
    bodyprintSizes: [],
    bodyprints: [],
    bodyprint: null,
    bodyprintInfo: null
  },
  computed: {
    ...mapGetters({
      getPointsByArea: "configuration/getPointsByArea",
      getGuide: "bodyprint/getGuide",
      getArea: "configuration/getArea",
      getAreas: "configuration/getAreas",
      getPoint: "configuration/getPoint",
      getNewCoordinates: "bodyprint/getNewCoordinates"
    })
  },
  getters: {
    isQueueItem: state => {
      return state.isQueueItem;
    },
    getMagnifier: state => {
      return state.magnifier;
    },
    getImage: state => projection => {
      if (projection === "left") {
        return state.imageSide;
      } else {
        return state.imageBack;
      }
    },
    getMeasureImage: state => {
      return state.measureImage;
    },
    // getActivePoints: (state, getters) => {
    //   this.getArea.handles[i]
    //   return state.activePoints;
    // },
    getBodyprintSizes: state => {
      return state.bodyprintSizes;
    },
    getBodyPrint: state => {
      return state.bodyprint;
    },
    getBodyPrintInfo: state => {
      return state.bodyprintInfo;
    },
    getBodyPrints: state => {
      return state.bodyprints;
    },
    getGrowthFactor: state => {
      return state.windowHeight / state.imageHeight;
    },
    // getSideGuides: state => {
    //   return state.bodyprint.guides.find(guide => guide.projection === "left");
    // },
    getSideWidth: (state, getters) => {
      return (
        getters.getSideGuides.crop.width *
        state.imageWidth *
        state.getGrowthFactor
      );
    },
    getBackWidth: (state, getters) => {
      return (
        getters.getBackGuides.crop.width *
        state.imageWidth *
        state.getGrowthFactor
      );
    },
    getPictureScale: state => {
      return state.windowHeight / state.imageHeight;
    },
    getPictureWidth: (state, getters) => {
      return state.imageWidth * getters.getPictureScale;
    },
    getPictureHeight: state => {
      return state.windowHeight;
    },
    getBackGuides: state => {
      return state.bodyprint.guides.find(guide => guide.projection === "back");
    },
    getCurrentStep: state => {
      return state.currentStep;
    },
    getSelectedMeasurePoint: state => {
      return state.selectedMeasurePoint;
    },
    getNewCoordinates: state => {
      return state.activeCoordinates;
    },
    getCropBack: state => {
      return state.bodyprint.guides.find(guide => guide.projection === "back");
    },
    getCropSide: state => {
      return state.bodyprint.guides.find(guide => guide.projection === "left");
    },
    getGuide: state => projection => {
      return state.bodyprint.guides.find(
        guide => guide.projection === projection
      );
    },
    getGrowthFactorCrop: state => {
      return state.windowHeight / (state.height * state.windowHeight);
    },
    getMeasureStep: state => {
      return state.measureStep;
    },
    getQueueItem: state => {
      return state.queueItem;
    },
    getQueueEmpty: state => {
      return state.queueEmpty;
    },
    getBodyprintMode: state => {
      return state.mode;
    },
    getImageSide: state => {
      return state.imageSide;
    },
    getImageBack: state => {
      return state.imageBack;
    }
  },
  mutations: {
    setQueueEmpty(state, payload) {
      state.queueEmpty = payload;
    },
    setQueueItem(state, payload) {
      state.isQueueItem = true;
      state.queueItem = payload;
    },
    setWindowHeight(state, payload) {
      // change 84 to desired
      let newHeight = payload.height - 84;
      let newScale = newHeight / state.imageHeight;
      let newWidth = state.imageWidth * newScale;
      if (newWidth < payload.width / 3) {
        state.windowHeight = newHeight;
      }
    },
    setZoom(state, payload) {
      state.zoom = payload;
    },
    // setModifiedPoint(state, payload) {
    //   console.log('modified point');
    //   console.log(state);
    //   console.log(payload);
    // },
    setMeasureImage(state, payload) {
      state.measureImage = payload;
    },
    SET_IMAGE_WIDTH(state, width) {
      state.imageWidth = width;
    },
    SET_IMAGE_HEIGHT(state, height) {
      state.imageHeight = height;
    },
    setCrop(state, payload) {
      payload[0].width = payload[0].x2 - payload[0].x;
      payload[0].height = payload[0].y2 - payload[0].y;
      payload[0].growthFactor =
        state.windowHeight / (payload[0].height * state.windowHeight);
      let guide = state.bodyprint.guides.find(
        guide => guide.projection === payload[1]
      );
      guide.crop = payload[0];
    },
    setCurrentStep(state, payload) {
      state.currentStep = payload;
    },
    updateConstraints(state, payload) {
      const point_0_index = state.bodyprint.points.findIndex(
        point => point.name === "point_0"
      );

      const point_38_index = state.bodyprint.points.findIndex(
        point => point.name === "point_38"
      );
      if (point_0_index !== -1) {
        const point_0 = state.bodyprint.points[point_0_index];

        if (point_0.y !== 0.0) {
          const guideIndex = state.bodyprint.guides.findIndex(
            guide => guide.projection === payload.projection
          );
          const guide = state.bodyprint.guides[guideIndex];
          const mutation = point_0.y * guide.crop.height;

          guide.crop.y = guide.crop.y + mutation;
          guide.crop.height = guide.crop.height - mutation;

          guide.crop.growthFactor =
            state.windowHeight / (guide.crop.height * state.windowHeight);
          Vue.set(state.bodyprint.guides, guideIndex, guide);

          point_0.y = 0.0;
          Vue.set(state.bodyprint.points, point_0_index, point_0);
        }
      }

      if (point_38_index !== -1) {
        const point_38 = state.bodyprint.points[point_38_index];
        if (point_38.y !== 1.0) {
          const guideIndex = state.bodyprint.guides.findIndex(
            guide => guide.projection === payload.projection
          );
          const guide = state.bodyprint.guides[guideIndex];
          const mutation = (1 - point_38.y) * guide.crop.height;

          guide.crop.height = guide.crop.height - mutation;
          guide.crop.growthFactor =
            state.windowHeight / (guide.crop.height * state.windowHeight);

          Vue.set(state.bodyprint.guides, guideIndex, guide);

          point_38.y = 1.0;
          Vue.set(state.bodyprint.points, point_38_index, point_38);
        }
      }
    },
    setPoint(state, payload) {
      const newPoint = {
        name: payload.name,
        x: payload.x,
        y: payload.y,
        z: payload.z
      };
      const index = state.bodyprint.points.findIndex(
        point => point.name === payload.name
      );
      if (index !== -1) {
        Vue.set(state.bodyprint.points, index, newPoint);
        return;
      }
      return state.bodyprint.points.push(newPoint);
    },
    setActivePoints(state, payload) {
      state.activePoints = payload;
    },
    deactivatePoints(state) {
      state.activeCoordinates = {};
    },
    setActiveCoordinates(state, payload) {
      state.activeCoordinates = payload;
    },
    setNewMeasureCoordinates(state, payload) {
      var point = state.activeCoordinates;
      if (payload === "top" || payload === "bottom") {
        if (point.canMoveY) {
          point.y = payload === "top" ? point.y - 1 : point.y + 1;
        }
      }
      if (payload === "left" || payload === "right") {
        if (point.canMoveX) {
          point.x = payload === "left" ? point.x - 1 : point.x + 1;
        }
      }
      state.activeCoordinates = point;
      //watcher..
      state.newCoordinates = point;

      return point;
    },
    setNewCropCoordinates(state, payload) {
      var position = state.activeCoordinates;
      if (payload === "top" || payload === "bottom") {
        position.y = payload === "top" ? position.y - 1 : position.y + 1;
      }
      if (payload === "left" || payload === "right") {
        position.x = payload === "left" ? position.x - 1 : position.x + 1;
      }
      state.activeCoordinates = position; //update new coordinates to selected item.
      state.newCoordinates = position; //update new coordinates for triggering watcher.
    },
    setMeasureStep(state, payload) {
      payload.step >= payload.areas.length ? (payload.step = 0) : false;
      payload.step < 0 ? (payload.step = 20) : false;
      state.measureStep = payload.step;
    },
    setImages(state, images) {
      var backguide = state.bodyprint.guides.find(x => x.projection === "back");
      var leftguide = state.bodyprint.guides.find(x => x.projection === "left");
      state.imageBack = images.find(x => x._id === backguide.image_id).data;
      state.imageSide = images.find(x => x._id === leftguide.image_id).data;
    },
    setBodyprintSizes(state, sizes) {
      state.bodyprintSizes = sizes;
    },
    setBodyprint(state, bodyprint) {
      state.bodyprint = bodyprint;
    },
    setBodyprints(state, bodyprints) {
      state.bodyprints = bodyprints;
    },
    setBodyprintInfo(state, bodyprintInfo) {
      state.bodyprintInfo = bodyprintInfo;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setMode(state, mode) {
      state.mode = mode;
    }
  },
  actions: {
    async setBodyprint({ commit }, bodyprint) {
      //logic for set to normal
      commit("setBodyprint", bodyprint);
    },
    setQueueItemStatus({ getters, rootGetters }, payload) {
      var id = getters.getQueueItem._id;
      return rootGetters["network/getClient"]
        .put(`bodyprint_queue/${id}`, payload, {
          headers: {
            // Overwrite Axios's automatically set Content-Type
            "Content-Type": "application/json"
          }
        })
        .then(() => {
          return true;
        });
    },
    cancelQueueItem({ getters, rootGetters }) {
      var id = getters.getQueueItem._id;
      return rootGetters["network/getClient"]
        .get(`bodyprint_queue/${id}/cancel`)
        .then(() => {
          return true;
        });
    },
    checkBodyprint({ dispatch, commit }) {
      commit("setMode", BodyprintMode.VIEW);
      dispatch("calculateBodyprint");
    },
    editBodyprint({ dispatch, commit }) {
      commit("setMode", BodyprintMode.EDIT);
      dispatch("calculateBodyprint");
    },
    calculateBodyprint({ commit, state, getters }) {
      const bodyprint = JSON.parse(JSON.stringify(state.bodyprint));
      var img = new Image();
      img.src = state.imageSide;

      var imgBack = new Image();
      var imgSide = new Image();

      imgBack.src = state.imageBack;
      imgSide.src = state.imageSide;

      setTimeout(() => {
        commit("SET_IMAGE_WIDTH", img.width);
        commit("SET_IMAGE_HEIGHT", img.height);

        let growthFactorSide =
          imgSide.height /
          (state.bodyprint.guides.find(x => x.projection === "left").crop
            .height *
            imgSide.height);

        let growthFactorBack =
          imgBack.height /
          (state.bodyprint.guides.find(x => x.projection === "back").crop
            .height *
            imgBack.height);

        let cropWidthSide = bodyprint.guides[0].crop.width;
        let cropWidthBack = bodyprint.guides[1].crop.width;
        var pointsProcessed = 0;
        if (bodyprint.points.length === 0) {
          commit("setLoading", false);
        }
        bodyprint.points.forEach(function(part) {
          pointsProcessed++;
          part.x =
            (part.x /
              (getters.getPictureWidth * growthFactorBack * cropWidthSide)) *
            (state.windowHeight / 1000);
          part.z =
            (part.z /
              (getters.getPictureWidth * growthFactorSide * cropWidthBack)) *
            (state.windowHeight / 1000);
          part.y = part.y / 1000;

          if (bodyprint.points.length === pointsProcessed) {
            commit("setBodyprint", bodyprint);
            setTimeout(() => {
              commit("setLoading", false);
            }, 1000);
          }
          if (cropWidthSide) {
            bodyprint.guides[0].crop.x2 =
              bodyprint.guides[0].crop.x + cropWidthSide;
            bodyprint.guides[0].crop.y2 =
              bodyprint.guides[0].crop.y + cropWidthSide;
            bodyprint.guides[0].crop.growthFactor =
              state.windowHeight /
              (bodyprint.guides[0].crop.height * state.windowHeight);
            bodyprint.guides[1].crop.x2 =
              bodyprint.guides[1].crop.x + cropWidthBack;
            bodyprint.guides[1].crop.y2 =
              bodyprint.guides[1].crop.y + cropWidthBack;
            bodyprint.guides[1].crop.growthFactor =
              state.windowHeight /
              (bodyprint.guides[1].crop.height * state.windowHeight);
          }
        });
      }, 1000);
    },
    postBodyprint({ state, getters, rootGetters }) {
      var imgBack = new Image();
      var imgSide = new Image();

      imgBack.src = state.imageBack;
      imgSide.src = state.imageSide;

      let growthFactorSide =
        imgSide.height /
        (state.bodyprint.guides.find(x => x.projection === "left").crop.height *
          imgSide.height);

      let growthFactorBack =
        imgBack.height /
        (state.bodyprint.guides.find(x => x.projection === "back").crop.height *
          imgBack.height);

      let cropWidthSide = state.bodyprint.guides.find(
        x => x.projection === "left"
      ).crop.width;
      let cropWidthBack = state.bodyprint.guides.find(
        x => x.projection === "back"
      ).crop.width;

      let bodyprint = JSON.parse(JSON.stringify(state.bodyprint));
      let bodyprintCopy = { ...bodyprint };

      var pointsProcessed = 0;
      bodyprintCopy.points.forEach(function(part) {
        pointsProcessed++;
        part.x =
          (part.x *
            (getters.getPictureWidth * growthFactorBack * cropWidthBack)) /
          (state.windowHeight / 1000);
        part.z =
          (part.z *
            (getters.getPictureWidth * growthFactorSide * cropWidthSide)) /
          (state.windowHeight / 1000);
        part.y = part.y * 1000;

        if (bodyprintCopy.points.length === pointsProcessed) {
          let id = state.bodyprint.id;
          if (!id) {
            id = state.bodyprint._id;
          }
          let user = state.bodyprint.user;
          let payload = bodyprintCopy;

          delete payload.status;
          delete payload.user;
          delete payload.id;
          delete payload._id;
          delete payload.last_updated_at;
          delete payload.created_at;
          delete payload.status_retailer;
          delete payload.completed_at;
          // payload.points = state.defaultPoints;

          rootGetters["network/getClient"].patch(
            `user/${user}/bodyprint/${id}`,
            payload
          );
        }
      });
    },
    checkQueue({ commit, rootGetters }, id) {
      return rootGetters["network/getClient"]
        .get(`bodyprint_queue/check/${id}`)
        .then(response => {
          if (response.data.inQueue) {
            var queueItem = response.data.item;
            commit("setQueueItem", queueItem);
          }
        });
    },
    getFirstInQueue({ commit, rootGetters }) {
      return rootGetters["network/getClient"]
        .get(`bodyprint_queue/next`)
        .then(response => {
          var bodyprint = response.data.body_print;
          commit("setBodyprint", bodyprint);
          commit("setQueueItem", response.data);
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data["MSG"]) {
              if (error.response.data["MSG"] === "QUEUE EMPTY") {
                commit("setQueueEmpty", true);
              }
            }
          }
        });
    },
    getBodyprintByUser({ commit, rootGetters }, payload) {
      return rootGetters["network/getClient"]
        .post(`bodyprint_queue/search-user`, {
          email: payload.email,
          code: payload.code
        })
        .then(response => {
          var bodyprint = response.data.body_print;
          commit("setBodyprint", bodyprint);
          commit("setQueueItem", response.data);
        });
    },
    getBodyprintsByUser({ commit, rootGetters }, payload) {
      return rootGetters["network/getClient"]
        .post(`bodyprint_queue/queue-search-user`, {
          email: payload.email,
          code: payload.code
        })
        .then(response => {
          var bodyprints = response.data;
          commit("setBodyprints", bodyprints);
        });
    },
    getBodyprintsForRetailer({ commit, rootGetters }, payload) {
      return rootGetters["network/getClient"]
        .post(`bodyprint_queue/queue-search-for-retailer`, payload)
        .then(response => {
          var bodyprints = response.data;
          commit("setBodyprints", bodyprints);
        });
    },
    requestBodyprint({ commit, rootGetters }, payload) {
      return rootGetters["network/getClient"]
        .post(`bodyprint_queue/request-bodyprint`, {
          queue_id: payload.id
        })
        .then(response => {
          var bodyprint = response.data.body_print;
          commit("setBodyprint", bodyprint);
          commit("setQueueItem", response.data);
        });
    },
    requestBodyprintToView({ commit, rootGetters }, payload) {
      return rootGetters["network/getClient"]
        .post(`bodyprint_queue/request-bodyprint-to-view`, {
          queue_id: payload.id
        })
        .then(response => {
          var bodyprint = response.data.body_print;
          commit("setBodyprint", bodyprint);
          commit("setQueueItem", response.data);
        });
    },
    requestBodyprintInfo({ commit, rootGetters }, payload) {
      return rootGetters["network/getClient"]
        .post(`bodyprint_queue/request-bodyprint-info`, {
          queue_id: payload.id
        })
        .then(response => {
          commit("setBodyprintInfo", response.data);
        });
    },
    requestBodyprintSizes({ commit, rootGetters }, payload) {
      return rootGetters["network/getClient"]
        .post(`bodyprint_queue/request-bodyprint-sizes`, {
          queue_id: payload.id
        })
        .then(response => {
          commit("setBodyprintSizes", response.data);
        });
    },
    getBodyprintByUserRaw({ commit, rootGetters }, payload) {
      return rootGetters["network/getClient"]
        .post(`bodyprint_queue/search-user-sizes`, {
          email: payload.email,
          code: payload.code
        })
        .then(response => {
          commit("setBodyprintSizes", response.data);
        });
    },
    getBodyprint({ dispatch, rootGetters }, params) {
      return rootGetters["network/getClient"]
        .get(`user/${params.id}/bodyprint`)
        .then(response => {
          var bodyprint;
          if (params.bodyprint_id) {
            bodyprint = response.data.find(x => x.id === params.bodyprint_id);
          } else {
            bodyprint = response.data[response.data.length - 1];
          }
          return dispatch("setBodyprint", bodyprint);
        });
    },
    getImages({ commit, rootGetters }, id) {
      return rootGetters["network/getClient"]
        .get(`admin-user/${id}/images`)
        .then(response => commit("setImages", response.data));
    },
    getBodyprintSizes({ commit, rootGetters }, params) {
      return rootGetters["network/getClient"]
        .get(`user/${params.user}/measurement/${params.bodyprint}`)
        .then(response => {
          commit("setBodyprintSizes", response.data);
        })
        .catch(error => commit("setBodyprintSizes", error.response.data));
    },
    solveConstraint({ commit, rootGetters }, constraint) {
      const payload = rootGetters["configuration/getPoint"](
        constraint.puppetPointName
      );
      let referenceValues = {
        x: 0,
        y: 0,
        z: 0
      };

      if (constraint.referencePointName) {
        const referencePoint = rootGetters["configuration/getPoint"](
          constraint.referencePointName
        );
        referenceValues = { ...referencePoint };
      }
      const axises = [];
      for (const axis in constraint.axis) {
        if (constraint.axis[axis]) {
          axises.push(axis);
        }
      }

      for (const index in axises) {
        const currentAxis = axises[index];
        if (constraint.relation === "<=") {
          if (
            payload[currentAxis] >
            referenceValues[currentAxis] + constraint.distance
          ) {
            payload[currentAxis] =
              referenceValues[currentAxis] + constraint.distance;
          }
        }
        if (constraint.relation === ">=") {
          if (
            payload[currentAxis] <
            referenceValues[currentAxis] + constraint.distance
          ) {
            payload[currentAxis] =
              referenceValues[currentAxis] + constraint.distance;
          }
        }
        if (constraint.relation === "=") {
          if (
            payload[currentAxis] !==
            referenceValues[currentAxis] + constraint.distance
          ) {
            payload[currentAxis] =
              referenceValues[currentAxis] + constraint.distance;
          }
        }
        if (constraint.relation === "avg") {
          const avgPoint = rootGetters["configuration/getPoint"](
            constraint.averageReferencePointName
          );
          payload[currentAxis] =
            (avgPoint[currentAxis] + referenceValues[currentAxis]) / 2;
        }
      }
      commit("setPoint", payload);
    },
    setPoint({ dispatch, commit, rootState }, point) {
      commit("setPoint", point);
      const constraints = rootState.configuration.constraints.filter(
        constraint => constraint.puppetPointName === point.name
      );

      for (let constraintIndex in constraints) {
        const constraint = constraints[constraintIndex];
        dispatch("solveConstraint", constraint);
      }

      for (let constraintIndex in rootState.configuration.constraints) {
        const constraint = rootState.configuration.constraints[constraintIndex];
        if (constraint.puppetPointName !== point.name) {
          dispatch("solveConstraint", constraint);
        }
      }

      // Update intersections
      rootState.configuration.intersections.forEach(intersection => {
        var line = intersection.line;
        var planarLineA = intersection.planarLineA;
        var planarLineB = intersection.planarLineB;

        if (
          point.name === line.startPointName ||
          point.name === line.endPointName ||
          point.name === planarLineA.startPointName ||
          point.name === planarLineA.endPointName ||
          point.name === planarLineB.startPointName ||
          point.name === planarLineB.endPointName
        ) {
          dispatch("solveIntersections", intersection);
        }
      });
      rootState.configuration.intersections.forEach(intersection => {
        var line = intersection.line;
        var planarLineA = intersection.planarLineA;
        var planarLineB = intersection.planarLineB;

        if (
          point.name !== line.startPointName &&
          point.name !== line.endPointName &&
          point.name !== planarLineA.startPointName &&
          point.name !== planarLineA.endPointName &&
          point.name !== planarLineB.startPointName &&
          point.name !== planarLineB.endPointName
        ) {
          dispatch("solveIntersections", intersection);
        }
      });
    },
    solveIntersections({ commit, rootGetters }, intersection) {
      var puppetPoint = rootGetters["configuration/getPoint"](
        intersection.puppetPointName
      );
      var newPoint;

      var lineStartPoint = rootGetters["configuration/getPoint"](
        intersection.line.startPointName
      );
      var lineEndPoint = rootGetters["configuration/getPoint"](
        intersection.line.endPointName
      );

      var planarLineAStartPoint = rootGetters["configuration/getPoint"](
        intersection.planarLineA.startPointName
      );
      var planarLineAEndPoint = rootGetters["configuration/getPoint"](
        intersection.planarLineA.endPointName
      );
      var planarLineBStartPoint = rootGetters["configuration/getPoint"](
        intersection.planarLineB.startPointName
      );
      var planarLineBEndPoint = rootGetters["configuration/getPoint"](
        intersection.planarLineB.endPointName
      );

      var planarVectorA = new VectorToFrom(
        planarLineAStartPoint,
        planarLineAEndPoint
      );
      var planarVectorB = new VectorToFrom(
        planarLineBStartPoint,
        planarLineBEndPoint
      );

      var plane = new Plane(
        planarLineAStartPoint,
        planarVectorA,
        planarVectorB
      );

      var intersectionPoint = plane.planeIntersectPoints(
        lineStartPoint,
        lineEndPoint
      );
      intersectionPoint.name = puppetPoint.name;
      newPoint = intersectionPoint;

      commit("setPoint", newPoint);
    }
  }
};
