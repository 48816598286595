import { mapMutations } from "vuex";

export default {
  data() {
    return {
      element: ""
    };
  },
  methods: {
    setActive(type) {
      var payload = {};
      if (!type) {
        payload = {
          x: this.interactPosition.x,
          y: this.interactPosition.y,
          position: this.barPosition
        };
      } else {
        payload = {
          x: type.x,
          y: type.y,
          pointNumber: type.pointNumber,
          canMoveX: type.canMoveX,
          canMoveY: type.canMoveY
        };
      }

      this.setActiveCoordinates(payload);
    },
    ...mapMutations({ setActiveCoordinates: "bodyprint/setActiveCoordinates" })
  }
};
